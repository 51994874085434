import React from 'react';

const WelcomeText = () => {
    return (
        <div>
            <div>
                <h1 className='text-2xl text-instant-teams-blue-Main font-semibold'>
                    Welcome to Instant Teams
                </h1>
            </div>
        </div>
    );
}

export default WelcomeText;
