import CompanyDetailsCard from "../Component/CompanyProfile/CompanyDetailsCard";
import CompanySocialAccountsCard from "../Component/CompanyProfile/CompanySocialAccountsCard";
import CompanyInformationCard from "../Component/CompanyProfile/CompanyInformationCard";
import Meta from "../../../../custom-ui/meta/Meta";

type Props = {};

const CompanyProfile_NewUI = (props: Props) => {
  return (
    <>
      <Meta title="Company Profile" />
      <div className="flex flex-col">
        <p className="text-[#15415E] font-[Gilroy-Bold] font-[400] text-[32px] leading-[39.62px]">
          Company Profile
        </p>
        <div className="flex flex-row lg:flex-col md:flex-col mt-[55px] gap-[30px]">
          <CompanyDetailsCard />
          <div className="flex flex-col w-1/2 md:w-full lg:w-full gap-y-[20px]">
            <CompanySocialAccountsCard />
            <CompanyInformationCard />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyProfile_NewUI;
