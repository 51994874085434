import {
  customDataRouteObject,
  customRoute,
} from "../../dataTypes/ReactRouter";
import IndexPage from "../../pages";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "../../components/fallback/Fallback";
import NotFoundPage from "../../pages/404";
import CompanyPage from "../../pages/employers/external/company";
import MultifactorEnrollmentPage from "../../pages/register/multifactor-enrollment";
import EmployerOnboardingPage from "../../pages/onboarding/employer-onboarding";
import {
  CompanyChildRoutes,
  ExternalApplicantChildRoutes,
  ExternalDashboardChildRoutes,
  ExternalJobListingChildRoutes,
  ExternalUserDetailsChildRoutes,
  ExternalOfferChildRoutes,
} from "./childrenRoutes";
import CompanyNotes from "../../pages/employers/external/company-notes";
import JobPage from "../../pages/jobs/JobPage";
import ExternalNewJob from "../../components/employerView/pages/ExternalNewJob";
import ExternalJobApplicantPage from "../../components/jobs/externalView/parts/ExternalJobApplicantPage";
import OfferLetterPreview from "../../components/jobs/pages/OfferLetterPreview";
import DashboardPage from "../../pages/dashboard/dashboard";
import ExternalJobOfferDetailsPage from "../../components/jobs/pages/ExternalJobOfferDetailsPage";
import ExternalUserDetails from "../../pages/users/external-user-details";
import ExternalNewOffersPage from "../../components/employerView/pages/ExternalNewOfferPage";
import { sharedChildren_newUi } from "../sharedRoutes_new_ui";
import { primaryPublicChildren_newui } from "../publicRoutes";
import JobDashboardNewUI from "../../components/employer/details/New_Ui/Pages/JobDashboard_newUI";
import JobDetails_newUI from "../../components/employer/details/New_Ui/Pages/JobDetails";
import SidebarWrapper_NewUI from "../../components/layout/sidebar/SidebarWrapper_NewUI";
import Users_NewUI from "../../components/employer/details/New_Ui/Pages/Users_NewUI";
import CompanyProfile_NewUI from "../../components/employer/details/New_Ui/Pages/CompanyProfile_NewUI";
import CurrentPlan_NewUI from "../../components/employer/details/New_Ui/Pages/CurrentPlan_NewUI";
import Home_NewUI from "../../components/employer/details/New_Ui/Pages/Home_NewUI";
import BillingInformation_NewUI from "../../components/employer/details/New_Ui/Pages/BillingInformation_NewUI";
import AddNewJobsNewUI from "../../components/employer/details/New_Ui/Pages/AddNewJobs_NewUI";

export const primaryRouteChildren_newUI_v2: customDataRouteObject[] = [
  ...primaryPublicChildren_newui,
  ...sharedChildren_newUi,

  {
    id: "Company",
    name: "Company",
    path: "/company/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <CompanyPage />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
    children: CompanyChildRoutes,
  },
  {
    id: "Dashboard",
    name: "Dashboard",
    path: "/dashboard/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <DashboardPage />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
    children: ExternalDashboardChildRoutes,
  },
  {
    id: "Home-NewUI",
    name: "Home",
    path: "/home",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <Home_NewUI />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
  },
  {
    id: "external jobs",
    name: "jobs",
    path: "/jobs",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <JobDashboardNewUI />
        </SidebarWrapper_NewUI>
        {/* <ExternalJobs />, */}
      </ErrorBoundary>
    ),
  },
  {
    id: "employer job details",
    name: "details",
    path: "/jobs/:id",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <JobDetails_newUI />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
    children: ExternalJobListingChildRoutes,
  },

  {
    id: "Company Profile",
    name: "Company Profile",
    path: "/company-profile",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <CompanyProfile_NewUI />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
  },
  {
    id: "Current Plan",
    name: "Current Plan",
    path: "/current-plan",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <CurrentPlan_NewUI />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
  },
  {
    id: "Users",
    name: "Users",
    path: "/users",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <Users_NewUI />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
  },
  {
    id: "Billing Information",
    name: "Billing Information",
    path: "/billing-information",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <BillingInformation_NewUI />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
  },
  {
    id: "Jop Applications ",
    name: "Applications ",
    path: "applications/:applicationId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <ExternalJobApplicantPage />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
    children: ExternalApplicantChildRoutes,
  },
  {
    id: "Employer new offer",
    name: "New Offer",
    path: "/offers/new/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <ExternalNewOffersPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "Employer Offer Details  ",
    name: "Employer Offer Details ",
    path: "offer-details/:offerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <ExternalJobOfferDetailsPage />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
    children: ExternalOfferChildRoutes,
  },
  {
    id: "employer new job",
    name: "new job",
    path: "/jobs/new",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
            <AddNewJobsNewUI/>
        </SidebarWrapper_NewUI>
 
      </ErrorBoundary>
    ),
  },
  {
    id: "Multifactor Enrollment",
    name: "Multifactor Enrollment",
    path: "/registration/multifactor-enrollment",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <MultifactorEnrollmentPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "Notes",
    name: "Notes",
    path: "/notes",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <CompanyNotes />
      </ErrorBoundary>
    ),
  },
  {
    id: "Offer Letter",
    name: "Offer Letter Preview",
    path: "/offer-letter/:offerId/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <OfferLetterPreview />
      </ErrorBoundary>
    ),
  },
  {
    id: "Onboarding",
    name: "Onboarding",
    path: "/onboarding",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmployerOnboardingPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "User Details",
    name: "User Details",
    path: "/users/:userId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SidebarWrapper_NewUI>
          <ExternalUserDetails />
        </SidebarWrapper_NewUI>
      </ErrorBoundary>
    ),
    children: ExternalUserDetailsChildRoutes,
  },
];

export const employerRoute_newUI_v2: customRoute[] = [
  {
    id: "Index",
    name: "Index",
    path: "/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <IndexPage />,
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    userType: ["A", "E"],
    children: primaryRouteChildren_newUI_v2,
  },
];
