import React from 'react';


const JobsSettingCard = ( {title,text}:{title:string, text:any}) => {
    return (
        <div className='h-[100%]  p-2 pl-5 bg-white rounded-lg py-3 px-3'>
            <div className=' w-12 h-12 rounded-full bg-gray-100 p-3 ml-[-5px]'>
                <img src="/images/jobdetail-vectore.png" alt="img" />
            </div>
            <div className='mt-4'>
                <p className='text-sm text-instant-teams-teal-L1 font-light'>
                   {title}
                </p>
                <p className='text-lg mt-2 font-semibold text-instant-teams-teal-Main'>{text}</p>
            </div>

        </div>
    );
}

export default JobsSettingCard;
