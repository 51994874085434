import { forwardRef } from "react";
import { cn } from "../../../util/cn";
import "./newUiComponentStyles.css";

interface TextAreaProps {
  name: string;
  display: string;
  placeholder?: string;
  labelClassName?: string;
  readOnly?: boolean;
  maxLength?: number;
  required?: boolean;
  error?: string;
  touched?: boolean;
  value?: any;
  isViewMode?: boolean;
}

const TextArea = forwardRef(
  (
    {
      name,
      display,
      placeholder = "",
      labelClassName = "",
      readOnly = false,
      maxLength = 200,
      required = false,
      error,
      touched,
      value,
      isViewMode = false,
      ...props
    }: TextAreaProps,
    ref: any,
  ) => {
    return (
      <div id={name} className="flex-auto">
        <div className="relative mb-4">
          {display && (
            <label
              className={cn(
                "text-[#15415E] font-semibold text-sm mb-1 block",
                labelClassName,
              )}
            >
              {display}{" "}
              {required && (
                <span className="text-[#ED3535] text-[16px]">*</span>
              )}
            </label>
          )}

          {isViewMode ? (
            <p className="font-[Gilroy-Medium] text-[#616161] font-[400] text-[15px] leading-[23px] tracking-wide">
              {value}
            </p>
          ) : (
            <>
              <textarea
                name={name}
                placeholder={placeholder}
                readOnly={readOnly}
                maxLength={maxLength}
                ref={ref} // forwarding the ref
                className={cn(
                  "peer w-full h-36 p-4 text-gray-700 text-opacity-60 bg-[#F4F7FA] rounded-lg shadow-sm border border-transparent focus:border-[#15415E] focus:ring-0 placeholder-gray-400 resize-none",
                  {
                    "cursor-not-allowed": readOnly,
                  },
                )}
                value={value}
                {...props}
              />

              <p className="text-right text-gray-400 text-xs mt-1">
                {!value.length ? "Max " : `${value.length}/`}
                {maxLength} Characters
              </p>

              {error && (
                <p className="text-[0.8rem] font-medium text-[red] mt-1">
                  {error}
                </p>
              )}
            </>
          )}

          <p className="new-ui-input peer-focus:border-[#15415E]"></p>
        </div>
      </div>
    );
  },
);

TextArea.displayName = "TextArea";

export default TextArea;
