import { useState } from "react";
import {
  ColumnDef,
  SortDirection,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./table";

import { DatatableSkeleton } from "./skeletons/DatatableSkeleton";
import { cn } from "../../../util/cn";
import ArrowsUpDown from "../icons/ArrowsUpDown";
// import { BsSortUpAlt, BsSortDown } from "react-icons/bs";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isloading: boolean;
  onRowClick?: (row: any) => void | undefined;
  isShadow?: boolean;
  isInactiveSelected?: boolean;
  minHeight?: number; // number in px
  // bodyHeight?: string;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  isloading,
  onRowClick = undefined,
  isShadow = true,
  isInactiveSelected = false,
  minHeight,
}: // bodyHeight = 'calc(100vh - 478px)',
DataTableProps<TData, TValue>) {
  // @ts-ignore
  const [sorting, setSorting] = useState<SortingState>([]);

  // console.log(isloading, '====================>isloading inside DataTable')
  // console.log(data, '====================>data inside DataTable')

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div
      className={cn("overflow-hidden rounded-[20px] bg-[white]", {
        "border-[1px] border-[#F8F8F8] p-[20px]": isShadow,
        "border-0 p-[10px]": !isShadow,
      })}
      style={{
        boxShadow: isShadow
          ? "0 0px 34px 0 rgba(30, 89, 109, 0.04)"
          : undefined,
        contain: "content",
        // height: "100%",
      }}
    >
      <div
      // style={{
      //   maxHeight: bodyHeight,
      //   position: "relative",
      // }}
      >
        {/* < div className="pl-[10px] pr-[20px]"> */}
        <div
          className="px-[10px]"
          style={{
            overflowY: "auto",
            // height: bodyHeight,
            // minHeight: "200px",
            ...(minHeight
              ? {
                  maxHeight: `${minHeight}px`,
                }
              : {
                  maxHeight: "calc(100vh - 325px)",
                }),
          }}
        >
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="border-none">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className="text-[16px] leading-[19.41px] font-[Gilroy-Medium] font-[400] text-[#15415E] py-[25px]"
                        style={{
                          // position: "sticky",
                          top: 0,
                          zIndex: 1,
                          backgroundColor: "#fff",
                          // position: "relative",
                          width: header.getSize(),
                        }}
                        colSpan={header.colSpan}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            {/* </Table> */}
            {/* </div> */}
            {/* <div
          className="p-[10px]"
          style={{
            overflowY: "scroll",
            height: bodyHeight,
            minHeight: "200px",
          }}
        > */}
            {/* <Table> */}
            <TableBody>
              {isloading && !data.length ? (
                // {isloading ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {/* Loading... */}
                    <DatatableSkeleton />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                        className={cn(
                          "text-[16px] leading-[19.6px] font-[Gilroy-SemiBold] font-[400] hover:bg-[#e7eef3]",
                          { "cursor-pointer": onRowClick !== undefined },
                          {
                            "text-[#00000080]": isInactiveSelected,
                            "text-[#15415E]": !isInactiveSelected,
                          },
                        )}
                        {...(onRowClick
                          ? {
                              onClick: () => onRowClick(row.original),
                            }
                          : {})}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className="pb-[20px] pt-[31px]"
                            style={{ width: cell.column.getSize() }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}

              {/* {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className="text-[#000000] font-medium text-[15px]"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="pb-[20px] pt-[31px]"
                        style={{ width: cell.column.getSize() }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export const IsSortedDataTableIcon = ({
  getIsSorted,
}: {
  getIsSorted: false | SortDirection;
}) => {
  // if (!getIsSorted) return <></>;

  return (
    <>
      <ArrowsUpDown />
      {/* {getIsSorted === "asc" ? (
        <BsSortUpAlt className="ml-2 h-4 w-4" />
      ) : (
        <BsSortDown className="ml-2 h-4 w-4" />        
      )} */}
    </>
  );
};
