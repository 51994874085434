import BulletDotSVG from "../../../../../custom-ui/icons/BulletDotSVG";

type BulletTextWrapperProps = {
  children: any;
};

const BulletTextWrapper = ({ children }: BulletTextWrapperProps) => {
  return (
    <div className="flex flex-row">
      <div className="w-[10px] h-[10px] mt-[5px] mr-[5px] text-[12px]">
        <BulletDotSVG className="w-full h-full" />
      </div>
      {children}
    </div>
  );
};

export default BulletTextWrapper;
