import * as z from "zod";

export const getCompanySocialAccountsSchema = () => {
  return z.object({
    facebook: z.string({
      required_error: "Facebook field is required",
    }),
    linkedin: z.string({
      required_error: "Linkedin field is required",
    }),
    twitter: z.string({
      required_error: "Twitter field is required",
    }),
  });
  // .superRefine((data, ctx) => {
  //   console.log(data, "=============>data inside superRefine");
  // });
};
