const TickSVG = ({ className = "" }: { className?: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g mask="url(#mask0_4148_9491)">
      <path
        d="M7.16172 13.4992L2.88672 9.22422L3.95547 8.15547L7.16172 11.3617L14.043 4.48047L15.1117 5.54922L7.16172 13.4992Z"
        // fill="white"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default TickSVG;
