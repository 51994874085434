import * as z from "zod";

export const getCompanyInformationSchema = () => {
  return z.object({
    intro: z
      .string({
        required_error: "About the Company field is required",
      })
      .min(1, {
        message: "About the Company is required",
      }),
    overview: z.string({
      required_error: "Overview field is required",
    }),
    mission: z.string({
      required_error: "Mission field is required",
    }),
  });
  // .superRefine((data, ctx) => {
  //   console.log(data, "=============>data inside superRefine");
  // });
};
