import React from "react";
import ExclamationSVG from "../../../../../custom-ui/icons/ExclamationSVG";

type HomeCardProps = {
  isExclamation: boolean;
  imagePath: string;
  title: string;
  description: string;
  onClick?: () => void;
};

const HomeCard = ({
  isExclamation,
  imagePath,
  title,
  description,
  onClick,
}: HomeCardProps) => {
  return (
    <div
      className="flex flex-col min-w-[570px] h-[413px] bg-[#FFFFFF] rounded-[20px] p-[10px] cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-row w-full justify-end h-[48px]">
        {isExclamation && <ExclamationSVG className="text-[#ED3535]" />}
      </div>
      <div className="self-center">
        <img src={imagePath} alt="Image" className="h-[128px] mt-[12px]" />
      </div>
      <p className="text-[#15415E] font-[Gilroy-Bold] text-center font-[400] text-[24px] leading-[29.71px] mt-[30px]">
        {title}
      </p>
      <p className="mt-[15px] font-[Gilroy-Medium] text-[#AFAFAF] self-center text-center w-[358px] text-[13px] leading-[15.77px]">
        {description}
      </p>
    </div>
  );
};

export default HomeCard;
