const PencilSVG = ({ className = "" }: { className?: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g mask="url(#mask0_3042_4672)">
      <path
        d="M3.13068 15.7508C2.86818 15.8133 2.64005 15.7477 2.4463 15.5539C2.25255 15.3602 2.18693 15.132 2.24943 14.8695L2.99943 11.2883L6.71193 15.0008L3.13068 15.7508ZM6.71193 15.0008L2.99943 11.2883L11.5869 2.70078C11.8744 2.41328 12.2307 2.26953 12.6557 2.26953C13.0807 2.26953 13.4369 2.41328 13.7244 2.70078L15.2994 4.27578C15.5869 4.56328 15.7307 4.91953 15.7307 5.34453C15.7307 5.76953 15.5869 6.12578 15.2994 6.41328L6.71193 15.0008ZM12.6557 3.75078L4.89318 11.5133L6.48693 13.107L14.2494 5.34453L12.6557 3.75078Z"
        // fill="white"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default PencilSVG;
