import React, { useState } from "react";

const FloatingInputWithAsterisk = ({ placeholder, onChange, value }:any) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative w-full">
      {/* Placeholder */}
      <label
        className={`absolute left-0 text-instant-teams-teal-Main ${
          isFocused || value
            ? "-top-4 text-sm text-gray-600"
            : "top-2 left-1 text-base"
        } transition-all duration-200 pointer-events-none`}
      >
        {placeholder}
        <span className="text-red-500 ml-1">*</span>
      </label>

      {/* Input */}
      <input
        type="text"
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="w-full  text-gray-800 text-base px-1 pt-4"
      />
    </div>
  );
};

export default FloatingInputWithAsterisk;
