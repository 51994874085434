import { useEffect, useState } from "react";
import { useAuth } from "../providers/auth";
import { Outlet, useNavigate } from "react-router-dom";
import ScrollToTop from "../providers/router";
import HeaderOld from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import RotatorDisplay from "./seekerRotator/parts/rotatorDisplay";
import Header from "../components/seeker/details/New_UI/Header/Header";
import { useLocation } from 'react-router-dom';
import { manageConfiguration } from "../data/configuration";
import EmpHeader from "../components/employer/details/New_Ui/Component/Header/EmpHeader_newUI";

function IndexPage(props) {
  const location = useLocation();
  const currentPath = location.pathname;
  const { user, userClaims } = useAuth();
  const navigate = useNavigate();
  const [newConfig, setNewConfig] = useState(false);
  const [newEmpConfig, setNewEMPConfig] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const [searchKeyWords, setSearchKeyWords] = useState();

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const data = await manageConfiguration();
        if (data[0]?.manage_new_ui) {
          setNewConfig(data[0]?.manage_new_ui);
          setNewEMPConfig(data[0]?.manage_new_ui_employer)
        }
      } finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };
    fetchSkills();
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
      user
        ? navigate("/dashboard/home", { replace: true })
        : navigate("/auth/signin", { replace: true });
    }
  }, [user, navigate]);

  return (
    <>
      {userClaims?.claims?.type === "S" ? (
        <>
          <ScrollToTop />
          {newConfig ? (
            <>
              {currentPath !== "/email-verification" &&
                currentPath !== "/registration/multifactor-enrollment" && (
                  <Header
                    searchKeyWords={searchKeyWords}
                    setSerachKeyWords={setSearchKeyWords}
                  />
                )}
              <Outlet />
            </>
          ) : (
            <>
              {loading ? <RotatorDisplay /> : <HeaderOld />}
              <main className="clear-both bg-gray-50 min-h-screen">
                <div className="grid grid-cols-12 gap-4 w-full">
                  <div className="xs:hidden sm:hidden md:col-span-4 lg:col-span-4 xl:col-span-4 2xl:col-span-4">
                    <RotatorDisplay />
                  </div>
                  <div className="xs:col-span-12 sm:col-span-12 md:col-span-8 lg:col-span-8 xl:col-span-8 2xl:col-span-8">
                    <Outlet />
                  </div>
                </div>
              </main>
              {loading ? <RotatorDisplay /> : <Footer />}
            </>
          )}
        </>
      ) : (
        <>
          {newConfig ? (
            <>
              {userClaims?.claims?.type !== "S" &&
                currentPath !== "/auth/signin" &&
                currentPath !== "/authaction" &&
                !currentPath.startsWith("/social") &&
                (
                  <>
                    {newEmpConfig && userClaims?.claims?.type === "E" ? <EmpHeader /> : <HeaderOld />}
                  </>)}
              <Outlet />
            </>
          ) : (
            <>
              <ScrollToTop />
              {loading ? <RotatorDisplay /> : <HeaderOld />}
              <Outlet />
              {loading ? <RotatorDisplay /> : <Footer />}
            </>
          )}
        </>
      )}
    </>
  );
}

export default IndexPage;
