
import {
    customDataRouteObject,
    customRoute,
  } from "../../dataTypes/ReactRouter";
  import IndexPage from "../../pages";
  import { ErrorBoundary } from "react-error-boundary";
  import Fallback from "../../components/fallback/Fallback";
  import NotFoundPage from "../../pages/404";
  import CompanyPage from "../../pages/employers/external/company";
  import MultifactorEnrollmentPage from "../../pages/register/multifactor-enrollment";
  import EmployerOnboardingPage from "../../pages/onboarding/employer-onboarding";
  import { primaryPublicChildren } from "../publicRoutes";
  import { sharedChildren } from "../sharedRoutes";
  import {
    CompanyChildRoutes,
    ExEmployeeChildRoutes,
    ExternalApplicantChildRoutes,
    ExternalDashboardChildRoutes,
    ExternalJobListingChildRoutes,
    ExternalUserDetailsChildRoutes,
    ExternalOfferChildRoutes,
  } from "./childrenRoutes";
  import SidbarWrapper from "../../components/layout/sidebar/SidebarWrapper";
  import EmployerSidebarHeader from "../../components/layout/sidebar/EmployerSidebarHeader";
  import ExternalUserManagemet from "../../pages/employers/external/user-management";
  import CompanyNotes from "../../pages/employers/external/company-notes";
  import ExternalJobs from "../../components/employerView/pages/ExternalJobs";
  import SidebarWrapper from "../../components/layout/sidebar/SidebarWrapper";
  import JobPage from "../../pages/jobs/JobPage";
  import ExternalNewJob from "../../components/employerView/pages/ExternalNewJob";
  import ExternalJobApplicantPage from "../../components/jobs/externalView/parts/ExternalJobApplicantPage";
  import JobsSidebarHeader from "../../components/layout/sidebar/JobSidebarHeader";
  import OfferLetterPreview from "../../components/jobs/pages/OfferLetterPreview";
  import ApplicationSidebarHeader from "../../components/layout/sidebar/ApplicationSidebarHeader";
  import OfferSidebarHeader from "../../components/layout/sidebar/OfferSidebarHeader";
  import DashboardPage from "../../pages/dashboard/dashboard";
  
  import DashboardHeader from "../../components/layout/sidebar/DashboardHeader";
  import EmployeePage from "../../components/employee/details/pages/EmployeePage";
  import EmployeeSidebarHeader from "../../components/layout/sidebar/EmployeeSidebarHeader";
  import UserDetailsSidebarHeader from "../../components/layout/sidebar/UserManagementSidebarHeader";
  import ExternalJobOfferDetailsPage from "../../components/jobs/pages/ExternalJobOfferDetailsPage";
  
  import ExternalUserDetails from "../../pages/users/external-user-details";
  import ExternalNewOffersPage from "../../components/employerView/pages/ExternalNewOfferPage";
  import { RouterProvider, createBrowserRouter } from "react-router-dom";
  import { useState, useEffect } from "react";
  import { sharedChildren_newUi } from "../sharedRoutes_new_ui";
  import { manageConfiguration } from "../../data/configuration";
  import { primaryPublicChildren_newui } from "../publicRoutes";


export const primaryRouteChildren_newUI_v1: customDataRouteObject[] = [
    ...primaryPublicChildren_newui,
    ...sharedChildren_newUi,
  
    {
      id: "Company",
      name: "Company",
      path: "/company/",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <SidbarWrapper
            options={CompanyChildRoutes}
            headerSection={EmployerSidebarHeader}
          >
            <CompanyPage />
          </SidbarWrapper>
        </ErrorBoundary>
      ),
      children: CompanyChildRoutes,
    },
    {
      id: "Dashboard",
      name: "Dashboard",
      path: "/dashboard/",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <SidebarWrapper
            options={ExternalDashboardChildRoutes}
            headerSection={DashboardHeader}
          >
            <DashboardPage />
          </SidebarWrapper>
        </ErrorBoundary>
      ),
      children: ExternalDashboardChildRoutes,
    },
    {
      id: "external jobs",
      name: "jobs",
      path: "/jobs",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <ExternalJobs />,
        </ErrorBoundary>
      ),
    },
    {
      id: "employer job details",
      name: "details",
      path: "/jobs/:id",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <SidebarWrapper
            options={ExternalJobListingChildRoutes}
            headerSection={JobsSidebarHeader}
          >
            <JobPage />
          </SidebarWrapper>
        </ErrorBoundary>
      ),
      children: ExternalJobListingChildRoutes,
    },
    {
      id: "Jop Applications ",
      name: "Applications ",
      path: "applications/:applicationId/",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <SidebarWrapper
            options={ExternalApplicantChildRoutes}
            headerSection={ApplicationSidebarHeader}
          >
            <ExternalJobApplicantPage />
          </SidebarWrapper>
        </ErrorBoundary>
      ),
      children: ExternalApplicantChildRoutes,
    },
    {
      id: "Employer new offer",
      name: "New Offer",
      path: "/offers/new/:applicationId",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <ExternalNewOffersPage />
        </ErrorBoundary>
      ),
    },
    {
      id: "Employer Offer Details  ",
      name: "Employer Offer Details ",
      path: "offer-details/:offerId/",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <SidebarWrapper
            options={ExternalOfferChildRoutes}
            headerSection={OfferSidebarHeader}
          >
            <ExternalJobOfferDetailsPage />
          </SidebarWrapper>
        </ErrorBoundary>
      ),
      children: ExternalOfferChildRoutes,
    },
    {
      id: "employer new job",
      name: "new job",
      path: "/jobs/new/:id",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <ExternalNewJob />
        </ErrorBoundary>
      ),
    },
    {
      id: "Multifactor Enrollment",
      name: "Multifactor Enrollment",
      path: "/registration/multifactor-enrollment",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <MultifactorEnrollmentPage />
        </ErrorBoundary>
      ),
    },
    {
      id: "Notes",
      name: "Notes",
      path: "/notes",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <CompanyNotes />
        </ErrorBoundary>
      ),
    },
    {
      id: "Offer Letter",
      name: "Offer Letter Preview",
      path: "/offer-letter/:offerId/",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <OfferLetterPreview />
        </ErrorBoundary>
      ),
    },
    {
      id: "Onboarding",
      name: "Onboarding",
      path: "/onboarding",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <EmployerOnboardingPage />
        </ErrorBoundary>
      ),
    },
    {
      id: "Users",
      name: "Users",
      path: "/users",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <ExternalUserManagemet />,
        </ErrorBoundary>
      ),
    },
    {
      id: "User Details",
      name: "User Details",
      path: "/users/:userId",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <SidebarWrapper
            options={ExternalUserDetailsChildRoutes}
            headerSection={UserDetailsSidebarHeader}
          >
            <ExternalUserDetails />
          </SidebarWrapper>
        </ErrorBoundary>
      ),
      children: ExternalUserDetailsChildRoutes,
    },
    {
      id: "Employer Employees",
      name: "Employees",
      path: "employee/:employeeId/details",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <SidebarWrapper
            headerSection={EmployeeSidebarHeader}
            options={ExEmployeeChildRoutes}
          >
            <EmployeePage />
          </SidebarWrapper>
        </ErrorBoundary>
      ),
      children: ExEmployeeChildRoutes,
    },
  ];
  
  export const employerRoute_newUI_v1: customRoute[] = [
    {
      id: "Index",
      name: "Index",
      path: "/",
      element: (
        // @ts-ignore
        <ErrorBoundary FallbackComponent={Fallback}>
          <IndexPage />,
        </ErrorBoundary>
      ),
      errorElement: <NotFoundPage />,
      userType: ["A", "E"],
      children: primaryRouteChildren_newUI_v1,
    },
  ];
  