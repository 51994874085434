
import React, { useEffect } from 'react';
import { formatRateRange, formatString } from '../../../../../seeker/details/New_UI/Components/util';
import { formatTimeDifference } from '../../util';
import { useNavigate } from 'react-router-dom';

const JobDashboardCard = ({ jobData }: { jobData: any }) => {
  const navigate = useNavigate();
  const { id, jobState, jobName, payRate, makeLiveDate, workSettings, workType, applyNowCounter, clicksCounter } = jobData || {};

  let jobStateClr = jobState === "live" ? "bg-green-300" : jobState === "closed_cancelled" || jobState === "closed_filled" ? "bg-red-300" : jobState === "on_hold" ? "bg-violet-300" : "bg-orange-300"
  const timeDifference = formatTimeDifference(makeLiveDate);

  const animationStyles = {
    animation: 'cardSlide 1s ease-in-out',
    transform: 'translate(0, 0)',
    '@keyframes cardSlide': {
      from: {
        transform: 'translate(-100%, 100%)',
        opacity: 0,
      },
      to: {
        transform: 'translate(0, 0)',
        opacity: 1,
      },
    },
  };

  return (


    <div className={`  rounded-xl ${jobStateClr}
     animate-card-`}
  
    >
      <div onClick={() => navigate(`/jobs/${id}`)} className=" mt-[3px] rounded-xl cursor-pointer bg-white  p-4 ">
        <div className="text-left mb-3">
          <h2 className="text-xl font-semibold text-instant-teams-blue-Main">{jobName}</h2>
          <div className='w-full mt-1 flex justify-between'>
            {payRate?.salary && <p className="text-instant-teams-blue-Main text-sm">{formatRateRange("salary", payRate?.salary)}</p>}
            {payRate?.hourly && <p className="text-instant-teams-blue-Main text-sm">{formatRateRange("salary", payRate?.hourly)}</p>}
            {payRate?.type == "commission" && <p className="text-instant-teams-blue-Main text-sm">Commission Based</p>}
            {payRate?.type == "unpaid_internship" && <p className="text-instant-teams-blue-Main text-sm">Unpaid Internship</p>}
            {timeDifference && <p className=" right-4 text-gray-400 text-sm">Posted: {timeDifference}</p>}
          </div>
        </div>
        <div className="flex gap-3 mt-1 mb-4">
          <span className="px-8 py-1 text-sm font-medium text-gray-700 bg-gray-100 rounded-full">{formatString(workSettings)}</span>
          <span className="px-8 py-1 text-sm font-medium text-gray-700 bg-gray-100 rounded-full">{formatString(workType)}</span>
        </div>
        <div className="border-t rounded-xl border-blue-200 mb-3"></div>
        <div className="flex space-x-10 items-center">
          <div className="">
            <span className="text-xl font-bold text-instant-teams-blue-Main">{clicksCounter}</span>
            <p className="text-sm text-instant-teams-blue-Main">Total Views</p>
          </div>
          <div className=" ">
            <span className="text-xl font-bold text-instant-teams-blue-Main">{applyNowCounter}</span>
            <p className="text-sm text-instant-teams-blue-Main">Total Clicks</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDashboardCard;

