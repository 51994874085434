import * as z from "zod";

const urlRegex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,3}(\/[^\s]*)?$/;

export const getCompanyDetailsSchema = () => {
  return z
    .object({
      companyName: z
        .string({
          required_error: "Company Name field is required",
        })
        .min(1, {
          message: "Company Name is required",
        }),
      companyWebsite: z
        .string({
          required_error: "Company Website field is required",
        })
        .min(1, {
          message: "Company Website is required",
        }),
      location: z
        .string({
          required_error: "Location field is required",
        })
        .min(1, {
          message: "Location is required",
        }),
      industryType: z.string({
        required_error: "Industry Type field is required",
      }),
      companySize: z.string({
        required_error: "Company Size field is required",
      }),
      hiringNeeds: z.string({
        required_error:
          "What best describes your hiring needs? field is required",
      }),
      jobsToFill: z.string({
        required_error: "How many jobs do you need to fill? field is required",
      }),
    })
    .superRefine((data, ctx) => {
      console.log(data, "=============>data inside superRefine");
      const { companyWebsite } = data;

      if (!urlRegex.test(companyWebsite)) {
        ctx.addIssue({
          code: "custom",
          message: "Invalid URL",
          path: ["companyWebsite"],
        });
      }
    });
};
