import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const RoundedButtonWithIcon = ({ icon, onClick,  bgColor = 'bg-gray-200', iconColor = 'text-black' }:any) => {
    return (
        <div
            className={`flex items-center w-3 h-3 p-5 justify-center rounded-full ${bgColor} cursor-pointer`}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={icon} className={iconColor} />
        </div>
    );
};



export default RoundedButtonWithIcon;
