import { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { cn } from "../../../../../util/cn";
import Meta from "../../../../custom-ui/meta/Meta";
import {
  DataTable,
  IsSortedDataTableIcon,
} from "../../../../custom-ui/new-ui/DataTable";
import DialogWrapper from "../../../../custom-ui/dialog/Dialog_NewUI";
import BuyCredits from "../Component/CurrentPlan/BuyCredits";
import SemiCircularProgress from "../Component/CurrentPlan/SemiCircularProgress";
import BulletTextWrapper from "../Component/CurrentPlan/BulletTextWrapper";

const datatableData = [
  {
    id: "asdfasfd1",
    jobTitle: "QA Automation",
    jobStatus: "live",
    postedBy: "Robert A. Coker",
    postingDate: "4 Oct, 2024",
    expiringDate: "15 Oct, 2024",
  },
  {
    id: "asdfasfd2",
    jobTitle: "API Tester",
    jobStatus: "closed",
    postedBy: "Tiffany Royce",
    postingDate: "2 Sep, 2024",
    expiringDate: "2 Oct, 2024",
  },
  {
    id: "asdfasfd1",
    jobTitle: "QA Automation",
    jobStatus: "live",
    postedBy: "Robert A. Coker",
    postingDate: "4 Oct, 2024",
    expiringDate: "5 Oct, 2024",
  },
  {
    id: "asdfasfd2",
    jobTitle: "API Tester",
    jobStatus: "closed",
    postedBy: "Tiffany Royce",
    postingDate: "2 Sep, 2024",
    expiringDate: "6 Oct, 2024",
  },
  {
    id: "asdfasfd1",
    jobTitle: "QA Automation",
    jobStatus: "live",
    postedBy: "Robert A. Coker",
    postingDate: "4 Oct, 2024",
    expiringDate: "7 Oct, 2024",
  },
  {
    id: "asdfasfd2",
    jobTitle: "API Tester",
    jobStatus: "expired",
    postedBy: "Tiffany Royce",
    postingDate: "2 Sep, 2024",
    expiringDate: "2 Oct, 2024",
  },
  {
    id: "asdfasfd1",
    jobTitle: "QA Automation",
    jobStatus: "live",
    postedBy: "Robert A. Coker",
    postingDate: "4 Oct, 2024",
    expiringDate: "10 Oct, 2024",
  },
  {
    id: "asdfasfd2",
    jobTitle: "API Tester",
    jobStatus: "closed",
    postedBy: "Tiffany Royce",
    postingDate: "2 Sep, 2024",
    expiringDate: "9 Oct, 2024",
  },
  {
    id: "asdfasfd1",
    jobTitle: "QA Automation",
    jobStatus: "live",
    postedBy: "Robert A. Coker",
    postingDate: "4 Oct, 2024",
    expiringDate: "15 Oct, 2024",
  },
  {
    id: "asdfasfd2",
    jobTitle: "API Tester",
    jobStatus: "closed",
    postedBy: "Tiffany Royce",
    postingDate: "2 Sep, 2024",
    expiringDate: "2 Oct, 2024",
  },
];

type CurrentPlanDatatable = {
  id: string;
  jobTitle: string;
  jobStatus: string;
  postedBy: string;
  postingDate: string;
  expiringDate: string;
};

type Props = {};

const CurrentPlan_NewUI = (props: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleBuyMoreCredits = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Meta title="Current Plan" />
      <div className="flex flex-col">
        <p className="text-[#15415E] font-[Gilroy-Bold] font-[400] text-[32px] leading-[39.62px]">
          Current Plan
        </p>
        <div className="flex flex-col mt-[20px] gap-[20px]">
          <div
            className={cn(
              "flex flex-row flex-wrap w-full bg-[#FFFFFF] h-auto min-h-[300px] rounded-[20px] p-[40px] gap-[30px]",
            )}
            style={{
              boxShadow: "0 0 34px rgba(30, 89, 109, 0.04)",
            }}
          >
            <div className="flex flex-col">
              <div className="h-[172px] w-[266px] flex justify-center items-center mt-[-20px] mb-[20px]">
                <SemiCircularProgress usedCredits={3} totalCredits={5} />
              </div>
              <div className="flex flex-row rounded-[20px] border-[1px] border-[rgba(138, 160, 174, 0.5)] p-[20px] justify-between w-[266px] font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px] text-[#15415E]">
                <p>Remaining Credits:</p>
                <p>2</p>
              </div>
            </div>

            <div className="flex flex-col gap-[15px] border-[1px] border-[rgba(138, 160, 174, 0.5)] rounded-[20px] w-[266px] h-[237px] p-[20px] text-[#15415E]">
              <div className="flex flex-row justify-between font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px]">
                <p>Total Credits:</p>
                <p>5</p>
              </div>
              <div className="h-[1px] bg-[#C8E3FB] w-full"></div>

              <div className="flex flex-1 flex-col gap-[15px] text-[#15415E] font-[400] overflow-y-auto pr-[10px] mr-[-10px]">
                <div className="flex flex-row justify-between items-center">
                  <p className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px]">
                    0
                  </p>
                  <p className="font-[Gilroy-Medium] font-[400] text-[12px] leading-[14.56px]">
                    Expires On: 04 Nov 2024
                  </p>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px]">
                    1
                  </p>
                  <p className="font-[Gilroy-Medium] font-[400] text-[12px] leading-[14.56px]">
                    Expires On: 15 Oct 2025
                  </p>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px]">
                    1
                  </p>
                  <p className="font-[Gilroy-Medium] font-[400] text-[12px] leading-[14.56px]">
                    Expires On: 15 Oct 2025
                  </p>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <p className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px]">
                    1
                  </p>
                  <p className="font-[Gilroy-Medium] font-[400] text-[12px] leading-[14.56px]">
                    Expires On: 15 Oct 2025
                  </p>
                </div>
              </div>

              <button
                className="bg-[#15415E] w-full rounded-[70px] text-[#FFFFFF] h-[40px] font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px]"
                onClick={handleBuyMoreCredits}
              >
                Buy More Credits
              </button>
            </div>

            <div className="min-w-[686px] w-auto h-[236px] rounded-[15px] bg-gradient-to-r from-[#0B619A] to-[#4DAAE8] flex-1 flex flex-col text-[#FFFFFF] p-[20px]">
              <div className="flex flex-row justify-between items-center">
                <p className="font-[Gilroy-Bold] font-[400] text-[20px] leading-[24.76px]">
                  5{" "}
                  <span className="font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px] ">
                    Jobs posting per month
                  </span>
                </p>
                <button className="underline font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] tracking-wider">
                  Cancel Plan
                </button>
              </div>

              <div className="flex flex-col flex-1 gap-[10px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px] tracking-wider mt-[20px]">
                <BulletTextWrapper>
                  Includes three (3) 30-day job postings
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Jobs must be used within each 30- day period
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Includes employer profile and logo
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Candidates are directed to your ATS
                </BulletTextWrapper>
                <BulletTextWrapper>
                  Automatic renewal and billing, cancel anytime
                </BulletTextWrapper>
              </div>

              <p className="mt-[20px] mb-[25px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px] tracking-wider">
                All packages include direct ATS apply, IT Marketplace badge for
                company website and analytics
              </p>

              <button className="underline font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] mb-[5px] tracking-wider">
                See All Benefits
              </button>
            </div>
          </div>

          <p className="text-[#15415E] font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px]">
            Credits Used
          </p>

          <div>
            <DataTable
              columns={columns}
              data={datatableData}
              isloading={false}
              isInactiveSelected={false}
              onRowClick={(row) => {
                console.log(row, "========>row");
              }}
            />
          </div>
        </div>
      </div>
      {isModalVisible && (
        <DialogWrapper
          title="Buy Credits"
          onClose={closeModal}
          displayCloseIcon={true}
          size="max-w-[1000px]"
        >
          <BuyCredits currentSubscription={"monthly"} />
        </DialogWrapper>
      )}
    </>
  );
};

// @ts-ignore
const columns: ColumnDef<CurrentPlanDatatable>[] = [
  {
    accessorKey: "jobTitle",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row justify-between w-full"
        >
          Job Title
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("jobTitle")}</div>;
    },
  },
  {
    accessorKey: "jobStatus",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row justify-between w-full"
        >
          Job Status
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      const jobStatusValue: string = row.getValue("jobStatus");

      return (
        <div
          className={cn(
            "rounded-[50px] w-[94px] h-[36px] p-[10px] font-[Gilroy-Medium] font-[400] text-[16px] leading-[19.41px] flex justify-center items-center",
            {
              "bg-[#CFFFD8] text-[#3DAB51]": jobStatusValue === "live",
              "bg-[#FFD2D2] text-[#E02F2F]": ["closed", "expired"].includes(
                jobStatusValue,
              ),
            },
          )}
        >
          <p className="first-letter:uppercase">{jobStatusValue}</p>
        </div>
      );
    },
  },
  {
    accessorKey: "postedBy",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row justify-between w-full"
        >
          Posted By
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("postedBy")}</div>;
    },
  },
  {
    accessorKey: "postingDate",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row justify-between w-full"
        >
          Posting Date
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("postingDate")}</div>;
    },
  },
  {
    accessorKey: "expiringDate",
    header: ({ column }) => {
      return (
        <button
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="flex flex-row justify-between w-full"
        >
          Expiring Date
          <IsSortedDataTableIcon getIsSorted={column.getIsSorted()} />
        </button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue("expiringDate")}</div>;
    },
  },
];

export default CurrentPlan_NewUI;
