import React, { useState } from 'react';

const CustomDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="relative inline-block">
      {/* Dropdown Button */}
      <button
        onClick={toggleDropdown}
        className="px-4 py-2 bg-white border border-gray-300 rounded-full flex items-center justify-between focus:outline-none"
      >
        <span className="text-gray-700 font-medium">Published</span>
        <svg
          className="w-4 h-4 ml-2 text-gray-700"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white rounded-lg shadow-lg z-10">
          <div className="py-2">
            <button
              className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              onClick={() => {
                console.log("On Hold clicked");
                setIsOpen(false); // close dropdown after selection
              }}
            >
              On Hold
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
