import React from 'react';

const PopupModal = ({ isOpen, onClose, children, title = "" }: {
    isOpen: boolean;
    onClose: () => void;
    children: any;
    title?: string;
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50  flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white max-h-[90vh] w-[90vw]   max-w-3xl overflow-auto  rounded-lg shadow-lg">
                {/* Modal Header */}
                <div className="flex justify-between  items-center p-4  border-gray-300">
                    {title && <h2 className="text-lg font-semibold text-instant-teams-blue-Main">{title}</h2>
                    }                    <button
                        onClick={onClose}
                        className="text-gray-600 flex justify-center items-center h-8 w-8 p-4 bg-gray-100 rounded-full hover:text-gray-900 focus:outline-none"
                    >
                        ✕
                    </button>
                </div>
              { title&& <div className='border-b ml-5 mr-5 mt-2'>

                </div>}

                {/* Modal Content */}
                <div className=" pb-2">{children}</div>
            </div>
        </div>
    );
};

export default PopupModal;
