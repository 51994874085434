import { useState, useEffect } from "react";
import CompanyProfileCard from "./CompanyProfileCardWrapper";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import toast from "react-hot-toast";
import { getCompanySocialAccountsSchema } from "../../../../../../schemas/CompanySocialAccountsSchema";
import Input from "../../../../../custom-ui/new-ui/Input";

import { EmployerNew } from "../../../../../../dataTypes/Employer";
import {
  updateEmployerNew,
  useEmployer,
} from "../../../../../../data/employer";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import { useAuth } from "../../../../../../providers/auth";

type CompanySocialAccountsCardProps = {};

const CompanySocialAccountsCard = ({}: CompanySocialAccountsCardProps) => {
  const { user } = useAuth();
  const employerId = user?.claims?.employerId;
  const { data: employer } = useEmployer(employerId ?? "");

  // @ts-ignore
  const [isInitialized, setIsInitialized] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [formSchema, setFormSchema] = useState(
    getCompanySocialAccountsSchema(),
  );

  type FormValues = z.infer<ReturnType<typeof getCompanySocialAccountsSchema>>;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      facebook: employer?.profile?.facebook ?? "",
      linkedin: employer?.profile?.linkedin ?? "",
      twitter: employer?.profile?.twitter ?? "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async ({ ...values }: FormValues) => {
    const { facebook, linkedin, twitter, ...restValues } = values;

    const employerCollectionData: EmployerNew = {
      displayName: employer?.displayName ?? null,
      location: employer?.location ?? null,
      companySize: employer?.companySize ?? null,
      hiringNeeds: employer?.hiringNeeds ?? null,
      jobsToFill: employer?.jobsToFill ?? null,
      profile: {
        url: employer?.profile?.url ?? null,
        sector: employer?.profile?.sector ?? null,
        facebook: facebook ?? null,
        linkedin: linkedin ?? null,
        intro: employer?.profile?.intro ?? null,
        twitter: twitter ?? null,
        overview: employer?.profile?.overview ?? null,
        mission: employer?.profile?.mission ?? null,
      },
    };

    await updateEmployerNew(employerId, employerCollectionData)
      .then(() => {
        toast.success("Social Accounts updated successfully");
        setIsEditing(!isEditing);
      })
      .catch(() => {
        toast.error("There was an error updating Social Accounts");
      });
  };

  const handleButtonEdit = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    reset();
    setIntialValuesToForm(employer);
    setIsEditing(false);
  };

  const setIntialValuesToForm = (employerData: any) => {
    if (!employerData || (employerData && !Object.keys(employerData).length))
      return;

    setValue("facebook", employer?.profile?.facebook ?? "");
    setValue("linkedin", employer?.profile?.linkedin ?? "");
    setValue("twitter", employer?.profile?.twitter ?? "");
  };

  useEffect(() => {
    if (employer && !isInitialized) {
      setIntialValuesToForm(employer);
      setIsInitialized(true);
    }
  }, [employer, isInitialized]);

  return (
    <>
      <CompanyProfileCard
        title="Social Accounts"
        onButtonClick={isEditing ? handleSubmit(onSubmit) : handleButtonEdit}
        onCancelClick={handleCancelClick}
        isEditing={isEditing}
        isSubmitting={isSubmitting}
        className="w-full"
      >
        <div className="flex flex-col gap-[30px] mt-[30px]">
          <form>
            <div className="flex flex-col gap-[30px]">
              <Controller
                control={control}
                name="facebook"
                render={(field) => (
                  <Input
                    {...field}
                    display="Facebook"
                    placeholder="Enter Facebook"
                    readOnly={!isEditing}
                    error={errors?.facebook?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="linkedin"
                render={(field) => (
                  <Input
                    {...field}
                    display="Linkedin"
                    placeholder="Enter Linkedin"
                    readOnly={!isEditing}
                    error={errors?.linkedin?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="twitter"
                render={(field) => (
                  <Input
                    {...field}
                    display="X"
                    placeholder="Enter X"
                    readOnly={!isEditing}
                    error={errors?.twitter?.message}
                  />
                )}
              />
            </div>
          </form>
        </div>
      </CompanyProfileCard>

      <ToastAlert />
    </>
  );
};

export default CompanySocialAccountsCard;
