import { useEffect, useState, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { manageConfiguration } from "../../../data/configuration";
import { useAuth } from "../../../providers/auth";
import { cn } from "../../../util/cn";
import { ExternalDashboardChildRoutes_NewUI } from "../../../react-router_routes/external/childrenRoutes";
import { customDataRouteObject } from "../../../dataTypes/ReactRouter";

const options = ExternalDashboardChildRoutes_NewUI;

function SidebarWrapper_NewUI({ children }: { children: any }) {
  const { role } = useAuth();
  const [newConfig, setNewConfig] = useState(false);
  const [newEmpConfig, setNewEMPConfig] = useState(false);

  const optionRoutes = useMemo(() => {
    return options.filter((option: any) => option.viewer.includes(role.id));
  });

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const data = await manageConfiguration();
        if (data[0]?.manage_new_ui) {
          setNewConfig(data[0]?.manage_new_ui);
          setNewEMPConfig(data[0]?.manage_new_ui_employer);
        }
      } finally {
        // Stop loading after data is fetched
      }
    };
    fetchSkills();
  }, []);

  return (
    <div className="absolute left-0 w-full min-h-screen overflow-hidden flex flex-row">
      <div
        className={cn(
          "relative left-0 min-h-screen overflow-hidden bg-gray-50 w-[16%] min-w-[340px]",
          {
            // "top-12": newEmpConfig,
            "top-28": !newEmpConfig,
          },
        )}
      >
        <div
          id="tabmenu"
          className="relative ml-[30px] mt-[103px] mb-[30px] bg-[white] text-[#15415E] font-medium pt-8 pb-36  h-[calc(100vh-15%)] overflow-y-auto overflow-x-hidden no-scrollbar rounded-[14px]"
        >
          <div>
            {optionRoutes.map(
              (pageObj: customDataRouteObject, index: number) => {
                const { svgIcon: SvgIcon } = pageObj;

                return (
                  <div key={pageObj.id}>
                    {pageObj.viewer !== undefined &&
                      pageObj.viewer.includes(role.id) &&
                      pageObj.menuDisplay && (
                        <div className="w-full text-[20px] leading-[24.76px]">
                          <NavLink
                            id={pageObj.id}
                            key={pageObj.id}
                            to={pageObj.path}
                            className={({
                              isActive,
                            }: {
                              isActive: boolean;
                            }) => {
                              return cn(
                                "flex flex-row gap-[10px] mb-[20px] py-4 pl-6 text-left items-center",
                                {
                                  "bg-[white] text-[#15415E] border-l-4 border-[#15415E] font-bold":
                                    isActive,
                                  " text-[#D4DFE3] hover:bg-gray-50 hover:border-l-4 hover:border-[#e1eeff]":
                                    !isActive,
                                },
                              );
                            }}
                          >
                            {SvgIcon}
                            {pageObj.name}
                          </NavLink>
                        </div>
                      )}
                  </div>
                );
              },
            )}
          </div>
        </div>
      </div>
      <div className={cn("relative w-[84%] overflow-hidden")}>
        <div
          className={cn(
            "mt-[73px] h-[calc(100vh-73px)] overflow-auto w-full",
            {
              "bg-gray-50 mb-0 pt-24 p-10": newEmpConfig,
              // "ml-[16%] bg-gray-50 mb-0 pt-24 p-10": newEmpConfig,
              "ml-[20%] pt-36 pr-10": !newEmpConfig,
            },
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default SidebarWrapper_NewUI;
