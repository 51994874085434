import React from 'react';

const JobSummaryCard = (
    { cardName, count = 0, cardBgColor, cardHighLightClr, textClr, cardShadowClr, imgUrl, onClick }
        : {
            cardName: string,
            count: number,
            cardBgColor: string,
            cardHighLightClr: string,
            textClr: string,
            cardShadowClr: string,
            imgUrl: string,
            onClick: () => void
        }

    //   <div } />
) => {
    return (
        <div className={` ${cardHighLightClr} rounded-lg`}>
            <div className="relative mb-[3px]" onClick={onClick}>
                <div className={`${cardBgColor} p-2 py-6 flex ${cardShadowClr} rounded-lg items-center justify-between relative cursor-pointer`}>
                    <div className='flex items-center'>
                        <img src={imgUrl} alt={`${cardName} Icon`} />
                        <p className={`ml-2 text-sm ${textClr}`}>{cardName}</p>
                    </div>
                    <h1 className='pr-5 text-instant-teams-blue-Main font-bold text-2xl'>{count}</h1>
                </div>
            </div>
        </div>
    );
};

export default JobSummaryCard;
