import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { formatRateRange } from '../../../../../seeker/details/New_UI/Components/util';
import JobsSettingCard from '../../Component-UI/JobsSettingCard';

// Main JobCard component
const JobCard = ({ jobData }: any) => {
  const {
    companyLogo,
    companyFullName,
    jobName,
    openings,
    workType,
    workTerm,
    workSettings,
    makeLiveDate,
    payRate,
    solutionType,
    applicationFlow,
    jobState,
    status, // assuming you have a status property to show the "DRAFT" label
  } = jobData || {};

  let jobStateClr = jobState === "live" ? "bg-[#C6FAD0] text-[#2CE252]"
    : jobState === "closed_cancelled" || jobState === "closed_filled" ? "bg-[#FAC7C7] text-[#E02F2F]"
      : jobState === "on_hold" ? "bg-[#D6D6FB] text-[#5B5BD3]"
        : jobState === "draft" ? "bg-[#FDE1AF] text-[#EF9D0D]" : ""
  
        let jobStatus= jobState === "live" ? "PUBLISHED"
        : jobState === "closed_cancelled"  ? "CANCELLED"
          : jobState === "on_hold" ? "HOLD"
            :jobState === "draft"? "DRAFT":jobState === "closed_filled"?"FILLED":""




  return (
    <div className={`w-full h-full ${jobStateClr} pt-[3px]  rounded-lg`}>
      <div className="w-full h-ful  bg-white rounded-lg py-3 px-3  relative">
        <div >
          <div className='flex justify-between'>
            <h2 className="text-xl font-bold text-instant-teams-blue-Main">
              {jobName}
            </h2>
            <div className='flex mr-[-2px]  items-center' >
              <div className='h-8 w-6 bg-white rotate-45 mt-[5px] mr-[-8px]'>
              </div>
              <div className={`${jobStateClr}  mr-[-10px] px-8 py-1 items-center '`}>
                <h1 className='text-lg '>
                {jobStatus}
                </h1>
              </div>

            </div>

          </div>
          <div className='mt-[-5px]'>
            {payRate?.salary && (
              <p className="text-sm text-gray-700">
                {formatRateRange("salary", payRate?.salary)}
              </p>
            )}
            {payRate?.hourly && <p className="text-sm text-gray-600">{formatRateRange("hoursPerWeek", payRate?.hourly)}</p>}
            {payRate?.type == "unpaid_internship" && <p className="text-sm text-gray-600">Unpaid Internship</p>}
            {payRate?.type != "unpaid_internship" && <p className="text-sm text-gray-700">
              Commission - {payRate?.commission ? payRate.commission : "NA"}
            </p>
            }
          </div>
          <hr className="mt-4 border-t border-gray-300" />
          <div className='mt-2'>
            <p className="text-sm  text-gray-500">Apply Link</p>
            <a
            
              href={applicationFlow}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-500 hover:underline"
            >
              <p className='text-instant-teams-blue-Main  mt-1'>
              {applicationFlow}
              </p>
            
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default JobCard;
