import React, { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomDropdown from '../Component-UI/CustomDropDown';
import { faEye, faHeart, faPencil } from '@fortawesome/free-solid-svg-icons';
import RoundedButtonWithIcon from '../Component-UI/RoundedButtonWithIcon';
import { useJobByIdForNewEmp } from '../../../../../data/listings';
import { useEffect } from 'react';
import JobCard from '../Component/JobDetail/JobDataCard';
import JobSpecificMetricCard from '../Component/JobSpecificMetricCard/JobSpecificMetricCard';
import JobsSettingCard from '../Component-UI/JobsSettingCard';
import { formatString } from '../../../../seeker/details/New_UI/Components/util';
import JobDescription from '../Component/JobDescription/JobDescription';
import PopupModal from '../Component/PopupModal/PopupModal';
import JobNewInformation from '../../../../jobs/pages/JobNewInformation';
import JobInformation from '../Component/JobInfo/JobInfo';
import JobsPreview_newUIEmp from '../Component/JobPreview/JobPreview';


const JobDetails_newUI = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    const { data } = useJobByIdForNewEmp(id);
    const [jobData, setJobData] = useState();
    const [modalType, setModelType] = useState()
    const workSettings = formatString(jobData?.workSettings)
    const workType = formatString(jobData?.workType)
    const workTerm = formatString(jobData?.workTerm)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
    const timestamp = { seconds: 1731349800, nanoseconds: 0 };
    const date = new Date(timestamp.seconds * 1000);
    const options: any = { month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options).replace(' ', ', ');


    useEffect(() => {
        if (data) {
            setJobData(data)
        }
    }, [data])

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        setIsModalPreviewOpen(false);
    };

    const toggleModalPreview = () => {
        setIsModalOpen(!isModalOpen);
        setIsModalPreviewOpen(!isModalOpen);
    };


    return (
        <div>
            <div className='flex justify-between  items-center w-full'>
                <div onClick={() => navigate("/jobs")} className=' cursor-pointer flex items-center'>
                    <div className='border-l-2 w-3 h-3 border-b-2 border-instant-teams-teal-Main rotate-45 mr-2'></div>
                    <p className='text-instant-teams-teal-Main text-base'> Back</p>
                </div>
                <div className='flex items-center'>
                    <CustomDropdown />
                    <div className='ml-2'>
                        <RoundedButtonWithIcon
                            icon={faEye}
                            onClick={toggleModalPreview}
                            bgColor="bg-instant-teams-blue-Main"
                            iconColor="text-white"
                        />
                    </div>
                </div>
            </div>
            {/* section-2 */}
            <div className='grid mt-5 space-x-5 grid-cols-5'>
                <div className='bg-[#E9F1F9]  p-4  rounded-lg col-span-4'>
                    <div className='flex justify-between items-center py-2 pb-4'>
                        <h1 className='text-xl text-instant-teams-blue-Main font-semibold'>
                            Job Details
                        </h1>
                        <div className='flex text-sm items-center font-thin text-instant-teams-teal-Main'>
                            <p>Posted By: </p>
                            <p className='ml-1 mr-2'>Umashankar</p>
                            {jobData?.jobState === "draft" && (<RoundedButtonWithIcon
                                icon={faPencil}
                                onClick={() => { toggleModal(); setModelType("jobInfo")} }
                                bgColor="bg-instant-teams-blue-Main"
                                iconColor="text-white"
                            />)}
                        </div>
                    </div>
                    <div className='grid grid-cols-4 space-x-5'>
                        <div className='col-span-3'>
                            <JobCard jobData={jobData} />
                        </div>
                        <div className='col-span-1'>
                            <JobsSettingCard title='Openings' text={jobData?.openings || 0} />
                        </div>
                    </div>
                    <div className='grid mt-5 grid-cols-4 space-x-4 '>
                        <JobsSettingCard title='Job Setting' text={workSettings} />
                        <JobsSettingCard title='Work Type' text={workType} />
                        <JobsSettingCard title='Start Date' text={formattedDate || 0} />
                        <JobsSettingCard title='Work Term' text={workTerm} />
                    </div>
                </div>
                <div className='col-span-1 space-y-4'>
                    <JobSpecificMetricCard imgUrl='/images/totalclick.png' cardText='Total Clicks' count={jobData?.clicksCounter || 0} />
                    <JobSpecificMetricCard imgUrl='/images/totalviewicon_emp.png' cardText='Total Views' count={jobData?.applyNowCounter || 0} />
                </div>
            </div>
            {/* section-3 */}
            <div className='bg-white h-full mt-4  p-4 rounded-lg'>
                <div className='flex justify-between'>
                    <h1 className='text-xl text-instant-teams-blue-Main font-semibold'>
                        Job Description
                    </h1>
                    {jobData?.jobState === "draft" && (<RoundedButtonWithIcon
                        icon={faPencil}
                        onClick={() => { toggleModal(); setModelType("jobDes")} }
                        bgColor="bg-instant-teams-blue-Main"
                        iconColor="text-white"
                    />)}
                </div>
                <JobDescription jobData={jobData} />
            </div>
            <PopupModal isOpen={isModalOpen} onClose={toggleModal} title={isModalPreviewOpen?"Job Post Preview":"Edit Job Details"}>
                <div className='mt-[-20px]'>
                   {isModalPreviewOpen? <JobsPreview_newUIEmp/>:
                    <JobInformation modalType={modalType} />}
                </div>
            </PopupModal>

        </div>
    )
}

export default JobDetails_newUI;
