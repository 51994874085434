import { forwardRef } from "react";
import { cn } from "../../../util/cn";
import "./newUiComponentStyles.css";

interface InputProps {
  name: string;
  display: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  required?: boolean;
  error?: string;
  touched?: boolean;
  labelClassName?: string;
}

const Input = forwardRef(
  (
    {
      name,
      display,
      placeholder = " ", // Empty placeholder for floating effect
      readOnly = false,
      maxLength,
      required = false,
      disabled = false,
      error,
      touched,
      labelClassName = "",
      ...props
    }: InputProps,
    ref: any,
  ) => {
    return (
      <div id={name} className="flex-auto">
        <div className="relative">
          <input
            type="text"
            name={name}
            placeholder={placeholder}
            readOnly={readOnly}
            maxLength={maxLength}
            ref={ref} // forwarding the ref
            className={cn(
              "peer mt-1 block w-full px-3 py-2 rounded-none sm:text-sm placeholder-gray-700 placeholder-opacity-0 focus:placeholder-opacity-40 new-ui-input text-[14px]",
              {
                "cursor-not-allowed": readOnly,
              },
            )}
            style={{ boxShadow: "none" }}
            disabled={readOnly || disabled}
            {...props}
          />
          <label
            htmlFor={name}
            className={cn(
              "absolute -top-4 text-[16px] text-[#1E596D] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#1E596D] peer-placeholder-shown:text-[16px] peer-focus:-top-4 peer-focus:text-[#1E596D] peer-focus:text-[16px]",
              labelClassName,
            )}
          >
            {display}{" "}
            {required && <span className="text-[#ED3535] text-[16px]">*</span>}
          </label>

          {error && (
            <p className="mt-1 text-sm text-left text-red-600">{error}</p>
          )}
        </div>
      </div>
    );
  },
);

Input.displayName = "Input";

export default Input;
