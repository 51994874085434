import React, { useState } from "react";
import AddJobStep1 from "../Component/AddJobForm/AddJobStep1";

const AddNewJobsNewUI = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const steps = [
        { id: 1, title: "Job Information" },
        { id: 2, title: "Company Details" },
        { id: 3, title: "Job Requirements" },
        { id: 4, title: "Compensation & Benefits" },
        { id: 5, title: "Review & Submit" },
    ];

    const handleNext = () => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleSaveAndExit = () => {
        alert("Form saved! You can continue later.");
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <AddJobStep1 />
                    </>
                );
            case 2:
                return (
                    <div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium">
                                Company Name<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Company Name"
                                className="w-full border rounded-lg px-4 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium">
                                Location<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Location"
                                className="w-full border rounded-lg px-4 py-2"
                            />
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium">
                                Skills Required<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Required Skills"
                                className="w-full border rounded-lg px-4 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium">
                                Experience<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Experience Required"
                                className="w-full border rounded-lg px-4 py-2"
                            />
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium">
                                Salary Range<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Salary Range"
                                className="w-full border rounded-lg px-4 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 font-medium">
                                Benefits<span className="text-red-500">*</span>
                            </label>
                            <textarea
                                placeholder="Enter Benefits"
                                className="w-full border rounded-lg px-4 py-2"
                            ></textarea>
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div>
                        <h2 className="text-gray-700 font-medium">Review & Submit</h2>
                        <p>Review all details and submit your job posting.</p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className=" ">
                <div>
                <h1 className="text-2xl mb-6 font-bold text-instant-teams-blue-Main">
                    Post a Job
                </h1>
            </div>
            <div className=" bg-white min-h-[65vh]  rounded-xl items-start mx-auto p-8">
      
                <div className="flex items-center mb-8">
                    {steps.map((step, index) => (
                        <div
                            key={step.id}
                            className={`flex-1 flex items-center ${index < currentStep ? "text-blue-600" : "text-gray-400"
                                }`}
                        >
                            {/* Step Circle */}
                            <div
                                className={`w-8 h-8 rounded-full flex items-center justify-center ${index < currentStep
                                    ? "bg-instant-teams-blue-Main text-white"
                                    : "bg-gray-200"
                                    }`}
                            >
                                {step.id}
                            </div>
                            {/* Line between Steps */}
                            {index < steps.length - 1 && (
                                <div
                                    className={`flex-1 h-1 ${index + 1 < currentStep
                                        ? "bg-instant-teams-blue-Main"
                                        : "bg-gray-200"
                                        } mx-2`}
                                ></div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Step Title */}
                <h1 className="text-xl font-bold mb-4">
                    {steps.find((step) => step.id === currentStep)?.title}
                </h1>

                {/* Form Content */}
                <div className="bg-white  p-6">
                    {renderStepContent()}
                </div>


            </div>

            {/* Navigation Buttons */}
            <div className="flex justify-between mt-6">
                <button
                    onClick={handleSaveAndExit}
                    className="px-12  py-3 bg-instant-teams-blue-Main text-white rounded-3xl "
                >
                    Save & Exit
                </button>
                <button
                    onClick={handleNext}
                    className="px-16 py-3 bg-instant-teams-blue-Main text-white rounded-3xl"
                    disabled={currentStep === steps.length}
                >
                    {currentStep === steps.length ? "Submit" : "Next"}
                </button>
            </div>
        </div>
    );
};

export default AddNewJobsNewUI;
