import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { cn } from "../../../util/cn";
import "./newUiComponentStyles.css";

interface SelectProps {
  value?: string;
  options: { id: string; label: string; [key: string]: any }[];
  optionValue?: string;
  name: string;
  display: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  required?: boolean;
  error?: string;
  touched?: boolean;
}

const Select = forwardRef(
  (
    {
      value: initialValue = "",
      options = [],
      optionValue = "code",
      name,
      display,
      placeholder = "Select an option",
      disabled = false,
      onChange,
      onBlur,
      required = false,
      error,
      touched,
    }: SelectProps,
    ref: any,
  ) => {
    const [value, setValue] = useState(initialValue);
    const [isValid, setIsValid] = useState(true);

    const handleChange = (event: any) => {
      const selectedValue = event.target.value;
      setValue(selectedValue);
      if (onChange) {
        onChange(selectedValue);
      }
    };

    const handleBlur = () => {
      if (!value && required) {
        setIsValid(false);
      } else {
        setIsValid(true);
        if (onBlur) {
          onBlur();
        }
      }
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <div id={name} className="relative">
        <select
          id={name}
          disabled={disabled}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          className={cn(
            "peer mt-1 block w-full px-3 py-2 rounded-none sm:text-sm new-ui-input",
            {
              "cursor-pointer": !disabled,
              "text-gray-700 text-opacity-40 new-ui-select": !value,
              "text-[#15415E]": value,
              "cursor-not-allowed": disabled,
            },
          )}
          style={{ fontSize: "13px", boxShadow: "none" }}
          ref={ref}
        >
          <option disabled value="">
            {placeholder}
          </option>
          {options.length ? (
            options.map((item) => (
              <option key={item.id} value={item[optionValue]}>
                {item.label}
              </option>
            ))
          ) : (
            <option disabled>N/A</option>
          )}
        </select>
        <label
          htmlFor={name}
          className={cn(
            "absolute -top-4 text-[16px] text-[#1E596D] bg-white px-1 transition-all duration-200",
            "peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-[16px]",
            "peer-focus:-top-4 peer-focus:text-[#1E596D] peer-focus:text-[16px]",
          )}
        >
          {display}{" "}
          {required && <span className="text-[#ED3535] text-[16px]">*</span>}
        </label>
        {error && (
          <p className="text-[0.8rem] font-medium text-red-600 mt-1">{error}</p>
        )}
      </div>
    );
  },
);

Select.displayName = "Select";

export default Select;
