const BulletDotSVG = ({ className = "" }: { className?: string }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M3.00015 2.00031L2 1.00015L3.00015 0L4.0003 1.00015L3.00015 2.00031Z"
      // fill="#8AA0AE"
      fill="currentColor"
    />
    <path
      d="M3.00015 6.00031L2 5.00015L3.00015 4L4.0003 5.00015L3.00015 6.00031Z"
      // fill="#8AA0AE"
      fill="currentColor"
    />
    <path
      d="M0 3.00015L1.00015 4.00031L2.0003 3.00015L1.00015 2L0 3.00015Z"
      // fill="#8AA0AE"
      fill="currentColor"
    />
    <path
      d="M5.00015 4.00031L4 3.00015L5.00015 2L6.0003 3.00015L5.00015 4.00031Z"
      // fill="#8AA0AE"
      fill="currentColor"
    />
  </svg>
);
export default BulletDotSVG;
