import { Skeleton } from "../skeleton";

export function DatatableSkeleton() {
  return (
    <div className="flex flex-col space-y-3">
      <Skeleton className="h-[40px] w-full rounded-xl" />
      <Skeleton className="h-[40px] w-full rounded-xl" />
    </div>
  );
}
