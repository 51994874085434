import React from 'react';

const JobSpecificMetricCard = ({ imgUrl, cardText, count = 0 }: { imgUrl: string, cardText: string, count: number }) => {
    return (
        <div className=' flex flex-col items-center justify-center rounded-xl  h-52  bg-[#C8E3FB]  p-4 relative'>

            <img className='h-8 w-8' src={imgUrl} />
            <p className='mt-1 text-sm text-instant-teams-teal-D1'>{cardText}</p>
            <h1 className='text-instant-teams-blue-Main font-bold mt-3  text-2xl'>
                {count}
            </h1>



        </div>
    );
}

export default JobSpecificMetricCard;
