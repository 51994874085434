const HomeSVG = ({ className = "" }: { className?: string }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={className}
  >
    <path
      d="M0 18V6L8 0L12.4064 3.32015C12.7574 3.58468 12.61 4.17908 12.2 4.3375C11.5365 4.59385 10.766 4.60739 10.2002 4.17632L8 2.5L2 7V16H5C5.55228 16 6 16.4477 6 17C6 17.5523 5.55228 18 5 18H0ZM8 18V16.1C8 15.75 8.0875 15.4208 8.2625 15.1125C8.4375 14.8042 8.675 14.5583 8.975 14.375C9.74167 13.925 10.5458 13.5833 11.3875 13.35C12.2292 13.1167 13.1 13 14 13C14.9 13 15.7708 13.1167 16.6125 13.35C17.4542 13.5833 18.2583 13.925 19.025 14.375C19.325 14.5583 19.5625 14.8042 19.7375 15.1125C19.9125 15.4208 20 15.75 20 16.1V18H8ZM10.15 16H17.85C17.2667 15.6667 16.65 15.4167 16 15.25C15.35 15.0833 14.6833 15 14 15C13.3167 15 12.65 15.0833 12 15.25C11.35 15.4167 10.7333 15.6667 10.15 16ZM14 12C13.1667 12 12.4583 11.7083 11.875 11.125C11.2917 10.5417 11 9.83333 11 9C11 8.16667 11.2917 7.45833 11.875 6.875C12.4583 6.29167 13.1667 6 14 6C14.8333 6 15.5417 6.29167 16.125 6.875C16.7083 7.45833 17 8.16667 17 9C17 9.83333 16.7083 10.5417 16.125 11.125C15.5417 11.7083 14.8333 12 14 12ZM14 10C14.2833 10 14.5208 9.90417 14.7125 9.7125C14.9042 9.52083 15 9.28333 15 9C15 8.71667 14.9042 8.47917 14.7125 8.2875C14.5208 8.09583 14.2833 8 14 8C13.7167 8 13.4792 8.09583 13.2875 8.2875C13.0958 8.47917 13 8.71667 13 9C13 9.28333 13.0958 9.52083 13.2875 9.7125C13.4792 9.90417 13.7167 10 14 10Z"
      // fill="#D4DFE3"
      fill="currentColor"
    />
  </svg>
);

export default HomeSVG;
