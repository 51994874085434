import React from 'react';
import FloatingInputWithAsterisk from '../FloatPlaceholderInput/FloatPlaceholderInput';


const AddJobStep1 = () => {
    return (
        <div>
            <div className="mb-4">

                <FloatingInputWithAsterisk
                    placeholder="Job Title"
                    value={""}
                    onChange={(e: any) => { }}
                />
            </div>
            <div>
                <label className="block text-gray-700 font-medium">
                    Openings<span className="text-red-500">*</span>
                </label>
                <input
                    type="text"
                    placeholder="Enter Number of Opennings"
                    className="w-full   px-4 py-2"
                />
            </div>
        </div>
    );
}

export default AddJobStep1;
